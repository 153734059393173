import React from "react";
import LogoPartners from "./LogoPartners";

const data = [
  {
    srcImage: "./images/logo-caixa.png",
    alt: "Caixa Fededral",
  },
  {
    srcImage: "./images/logo-bahia.png",
    alt: "Estado da Bahia",
  },
  {
    srcImage: "./images/logo-osc-legal.png",
    alt: "OSC Legal",
  },
  {
    srcImage: "./images/logo-bem-maior.png",
    alt: "Movimento Bem Maior",
  },
];

function Partners() {
  return (
    <section id="partners" className="main_partners container reveal">
      <div className="main_partners_text">
        <h2>Parceiros</h2>
        <p>
          conheça as organizações que contribuem para tornar realidade nossos
          sonhos de transformar comunidades por meio da arte e da cultura
        </p>
      </div>

      <div className="main_partners_brands">
        {data.map(({srcImage, alt}, index) => {
            return <LogoPartners srcImage={srcImage} alt={alt} key={index} />
        })}
      </div>
    </section>
  );
}

export default Partners;
