import React from "react";
import ProjectTagCard from "./ProjectCardTag";

function showReadMore(e) {
  //Get isMobile
  const windowWidth = window.innerWidth;
  if(windowWidth < 1024) return;

  e.currentTarget.classList.add('visible');
}

function hideReadMore(e) {
  e.currentTarget.classList.remove('visible');
}

function ProjectCard({
  id,
  name,
  expiration,
  impact,
  partners,
  image,
  openModal
}) {

  const expired = expiration.includes('presente');
  return (
    <div id={id} className="projects_card" onMouseOver={showReadMore} onMouseLeave={hideReadMore} onClick={openModal}>
      <img src={image} alt="Imagem Card" />

      <div className="projects_card_content">
        <h3 className="projects_card_title">{name}</h3>
        <div className="projects_card_tags">
          <ProjectTagCard active={expired} expiration={expiration} impact={impact} partners={partners}/>
        </div>
      </div>
      <span className="projects_card_read-more">Clique para saber mais</span>
    </div>
  );
}

export default ProjectCard;
