import React from "react";

function People({name, position, image}) {
  return (
    <article className="whodoes_people">
      <img
        src={image}
        alt={"Foto " + name}
        title={name}
      />
      <h3>{name}</h3>
      <p>{position}</p>
    </article>
  );
}

export default People;
