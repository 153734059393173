import React from "react";

function LogoPartners({srcImage, alt, index}) {
  return (
    <div className="main_partners_brands_logo">
      <img
        src={srcImage}
        alt={alt}
        title={alt}
      />
    </div>
  );
}

export default LogoPartners;
