import React from "react";
import srcImage from "../../assets/images/logo-tiziiu.png";

function getCurrentYear() {
  const newDate = new Date();
  const currentYear = newDate.getFullYear();

  return currentYear;
}

function Footer() {
  return (
    <footer className="container">
      <p className="footer_content">
        &copy; {getCurrentYear()} - Desenvolvido por
        <a
          href="https://www.tiziiu.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={srcImage} alt="Logo Tiziiu" className="footer_content_logo" />
        </a>
      </p>
    </footer>
  );
}

export default Footer;
