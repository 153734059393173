import React from "react";
import Link from "./Link";

const Menu = () => {
  return (
    <nav className="main_header_content_nav">
      <ul className="menu" role="menu">
        <Link name="O que fazemos" href="./" dataID="about-us"/>
        <Link name="Quem faz" href="./" dataID="whodoes"/>
        <Link name="Projetos" href="./" dataID="projects" />
        <Link name="Agenda" href="./" dataID="calendar-events" />
        <Link name="Parceiros" href="./" dataID="partners" />
        <Link name="Contato" href="./" dataID="contact-us" />
      </ul>
    </nav>
  );
};

export default Menu;
