import React from "react";
import {scrollTo} from "../../assets/helpers/scrollHelper";

function Link({ name, dataID }) {
  return (
    <li className="menu-item">
      <a className="menu-link" href="./" title={name} data-id={dataID} onClick={scrollTo}>
        {name}
      </a>
    </li>
  );
}

export default Link;
