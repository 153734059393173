import React from "react";
import {scrollTo} from "../../assets/helpers/scrollHelper";
import srcImage from "../../assets/images/image-banner.png";

function BannerCta() {
  return (
    <section className="banner_cta container">
      <div className="banner_cta_content">
        <h2>
          CONSTRUÍDA POR <span className="banner_cta_content_people">GENTE</span><br/>
          E ALIMENTADA POR <span className="banner_cta_content_dreams">SONHOS</span>
        </h2>

        <p>
          Somos a Associação Arte e Cultura do Bairro Baianão (AACBB) fundada no
          ano de 2003 em Porto Seguro, por moradores do bairro periférico do
          Complexo Frei Calixto.
        </p>
      </div>

      <div className="banner_cta_img">
        <img
          src={srcImage}
          alt="Projeto Baianarte"
          title="Projeto Baianarte"
        />
      </div>
      <div className="banner_cta_arrow" data-id="about-us" onClick={scrollTo}>
        <img src="./svg/icon-arrow-down.svg" alt="Icone flechas" />
      </div>
    </section>
  );
}

export default BannerCta;
