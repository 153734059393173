import React from "react";
import Logo from "./Logo";
import Menu from "./Menu";
import MenuMobile from "./MenuMobile";

const Header = () => {
  return (
    <header className="main_header">
      <div className="container main_header_content">
        <Logo />
        <Menu />
        <MenuMobile />
      </div>
    </header>
  );
};

export default Header;
