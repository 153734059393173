import React, {useRef} from "react";
import ProjectCard from "./ProjectCard";
import Slider from "react-slick";
import ProjectModal from "./ProjectModal";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import data from "../../data-projects.json";

function ProjectsComponent() {
  const sliderRef = useRef();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  function closeModal(e) {
    e.preventDefault();
    
    if(e.key === 'Escape' || e.type === 'click'){
      const modal = document.querySelector('.project_modal');
      const body = document.querySelector("body");
      const menu = document.querySelector(".main_header");
    
      body.style.overflow = "auto";
      modal.classList.replace("show", "hide");
      modal.setAttribute("aria-hidden", true);
      menu.classList.add("main_header--sticky");
    }  
  }

  function openModal(e) {
    //Get isMobile
    const windowWidth = window.innerWidth;
    if(windowWidth < 1024) return;

    //Get Modal Elements
    const body = document.querySelector('body');
    const menu = document.querySelector('.main_header');
    const modal = document.querySelector('.project_modal');
    const slideNumber = e.currentTarget.getAttribute('id');
    sliderRef.current.slickGoTo(slideNumber -1);

    modal.classList.replace('hide', 'show');    
    body.style.overflow = 'hidden';
    menu.classList.remove('main_header--sticky');
    body.addEventListener('keyup', closeModal)
  }

  return (
    <section id="projects" className="projects container reveal">
      <div className="projects_content">
        <h2>Projetos</h2>
        <p>
          trabalhamos há mais de 10 anos em prol do desenvolvimento social e da
          formação do cidadão em situação de vulnerabilidade.
        </p>

        <p>
          nesse período impactamos +10.000 famílias por meio dos nossos
          projetos.
        </p>
        <p>conheça algumas das nossas ações.</p>
      </div>

      <div className="projects_cards">
        <Slider {...settings} >
          {data.map(
            ({id, name, expiration, impact, partners, image}) => {
              return (
                <ProjectCard
                  key={id}
                  id={id}
                  name={name}
                  expiration={expiration}
                  impact={impact}
                  partners={partners}
                  image={image}
                  openModal={openModal}
                />
              );
            }
          )}
        </Slider>
      </div>
      <ProjectModal  sliderRef={sliderRef} closeModal={closeModal}/>
    </section>
  );
}

export default ProjectsComponent;
