import React from "react";
import imageSrc from "../../assets/images/image-about-us.png";
import Article from "./Article";

const data = [
  {
    title: "Oportunidades",
    text: "temos como missão gerar oportunidades a famílias carentes que em sua maioria, sofrem árduas limitações sociais.",
    image: "./svg/icon-triangle.svg",
    alt: "Icone Triângulo",
  },
  {
    title: "Projetos",
    text: "ofertamos acesso a cultura, esporte, educação, saúde e cidadania.",
    image: "./svg/icon-circle.svg",
    alt: "Icone Círculo",
  },
  {
    title: "Indivíduo",
    text: "lutamos pela valorização igualitária a todos os atendidos.",
    image: "./svg/icon-rainbow.svg",
    alt: "Icone Arco-Íris",
  },
];

function AboutUs() {
  return (
    <section id="about-us" className="about-us container reveal">
      <h2 className="about-us_title">O que fazemos</h2>

      <div className="about-us_image">
        <img
          src={imageSrc}
          alt="Casal de Bailarinos"
          title="Casal de Bailarinos"
        />
      </div>

      <div className="about-us_articles">
        {data.map(({ title, text, image, alt }) => {
          return (
            <Article key={title}
              title={title}
              text={text}
              image={image}
              alt={alt}
            />
          );
        })}
      </div>
    </section>
  );
}

export default AboutUs;
