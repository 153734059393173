import React from 'react';
import './App.scss';
import Header from './components/Header/Header';
import BannerCta from './components/BannerCta/BannerCta';
import AboutUs from './components/AboutUs/AboutUs';
import WhoDoes from './components/WhoDoes/WhoDoes';
import ProjectsComponent from './components/ProjectsComponent/ProjectsComponent';
import CalendarEvents from './components/CalendarEvents/CalendarEvents';
import Partners from './components/Partners/Partners';
import scrollReveal from './assets/helpers/revealHelper';
import { scrollHeader, scrollActiveLink } from './assets/helpers/scrollHelper';
import ContactUs from './components/ContactUs.jsx/ContactUs';
import MainFooter from './components/MainFooter/MainFooter';
import Footer from './components/Footer/Footer';

function App() {
  window.addEventListener('scroll', scrollReveal);
  window.addEventListener('scroll', scrollHeader);
  window.addEventListener('scroll', scrollActiveLink);

  return (
    <>
      <main>
        <Header/>
        <BannerCta />
        <AboutUs />
        <WhoDoes />
        <ProjectsComponent />
        <CalendarEvents />
        <Partners />
        <ContactUs />
        <MainFooter />
      </main>
      <Footer />
    </>
  );
}

export default App;
