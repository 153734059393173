function scrollReveal(e) {
    const windowTopPos = e.currentTarget.pageYOffset;
    const elementsToReveal = document.querySelectorAll('.reveal');

    elementsToReveal.forEach(element => {
        const windowHeight = window.innerHeight;
        const elementPosTop = element.getBoundingClientRect().top;
        const elementVisible = 100;

        if (windowTopPos < 10) {
            element.classList.remove('showed');
            return;
        }

        if (elementPosTop < windowHeight + elementVisible) {
            element.classList.add('showed');
        } else {
            element.classList.remove('showed');
        }
    });
}

export default scrollReveal;