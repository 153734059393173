const body = document.querySelector('body');

/**
 * This function scrolls the body when clicking on the link
 * @param {*} e 
 * @returns 
 */
export function scrollTo(e) {
    e.preventDefault();

    // Get ID and Elements
    const dataID = e.currentTarget.dataset.id;  
    const elementToScroll = document.getElementById(dataID);
    const btnMobile = document.querySelector('.btn-menu-mobile');
    const menuMobile = document.querySelector('.main_header_content_nav_mobile');
  
    if(!dataID || !elementToScroll) return;
  
    // Get position element, if element exists
    const elementPosTop = elementToScroll.getBoundingClientRect().top + window.scrollY - 100;
  
    window.scroll({
      top: elementPosTop,
      behavior: 'smooth'
    })

    btnMobile.setAttribute('aria-expanded', false);
    btnMobile.setAttribute('aria-label', 'Abrir Menu');
    menuMobile.classList.remove('active');
    body.style.overflowY = 'auto';
}

/**
 * This function changes the position style of the scrollable header
 * @param {*} e 
 */
export function scrollHeader(e){
    e.preventDefault();

    const header = document.querySelector('.main_header');
    const windowTopPos = e.currentTarget.pageYOffset;

    if(windowTopPos !== 0){
        header.classList.add('main_header--sticky');
    } else {
        header.classList.remove('main_header--sticky');
    }
}

/**
 * This function adds a tag to the link when your section enters in the viewport
 * @returns 
 */
export function scrollActiveLink () {
    // Update active-link with Scroll
    let sections = document.querySelectorAll('section');
    
    // Position Body
    let posTop = Math.abs(body.getBoundingClientRect().top);

    //Arrival in the top
    if(posTop < 10){
        return;
    }

    sections.forEach(section =>{
        posTop = section.getBoundingClientRect().top;
        if(posTop < 300 && posTop > 0){
            let id = section.getAttribute('id');
            let link = document.querySelectorAll('.menu a[data-id=' + id);
            let linkActived = document.querySelectorAll('.menu a.active-link');
            // eslint-disable-next-line no-unused-expressions
            linkActived ? linkActived.forEach(item => item.classList.remove('active-link')) : null;
            // eslint-disable-next-line no-unused-expressions
            link ? link.forEach(item => item.classList.add('active-link')) : null;
        }   
    })
    
}