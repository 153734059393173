import React from "react";
import Input from "./Input";

function phoneMask(e){
  const keyPress = e.keyCode;
  if(keyPress === 8 || keyPress === 46) return;

  const phone = e.currentTarget; 
  // When start writting the phone number, add the first "("
  if(phone.value.length === 0){
    phone.value = '(' + phone.value;
  }
  
  // When the length value is equal to 3, add the last ")"
  if(phone.value.length === 3){
    phone.value = phone.value + ') ';    
  }

  // When the length value is equal to 9, add the separator "-", to make it easier to see
  if(phone.value.length === 10){
    phone.value = phone.value + '-';
  }
}

// Validation phone helper
function validationPhone(phone){
  const regexEmail = /\(\d{2}\)\s\d{4,5}-\d{4,5}/;
  return regexEmail.test(phone);
}

// Validation email helper
function validationEmail(email){
  const regexEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return regexEmail.test(email);
}


const validationForm = async (e) => {
  e.preventDefault();
  // Get elements
  const name = document.querySelector('#name');
  const phone = document.querySelector('#phone');
  const email = document.querySelector('#email');
  const message = document.querySelector('#message');

    const url = 'https://tiziu-functions.netlify.app/.netlify/functions/AacbbSendMail';
    const secretKey = 'bf70938cbada9d731e649bb2fd77c091';

    // Get messages container
    let errorMessageName = document.querySelector('#name + .error-message');
    let errorMessagePhone = document.querySelector('#phone + .error-message');
    let errorMessageEmail = document.querySelector('#email + .error-message');
    let errorMessageText = document.querySelector('#message + .error-message');
    let emailMessage = document.querySelector('.email-message');

    // Validation Name
    if(!name.value || name.value.length < 3){
        name.classList.add('error-border');
        errorMessageName.innerHTML = 'O campo deve ser preenchido!';
        errorMessageName.style.display = 'block';
        return;
    } else {
        name.classList.remove('error-border');
        errorMessageName.style.display = 'none';
    }

    // Handle Phone Number or Email
    if(phone.value || email.value){
      // Validation Phone
      if (phone.value && !validationPhone(phone.value)){
          phone.classList.add('error-border');
          errorMessagePhone.innerHTML = 'Por favor, insira um número válido!';
          errorMessagePhone.style.display = 'block';
          return;
      } else {
          phone.classList.remove('error-border');
          errorMessagePhone.style.display = 'none';
      }

      // Validation Email
      if (email.value && !validationEmail(email.value)){
          email.classList.add('error-border');
          errorMessageEmail.innerHTML = 'Por favor, insira um email válido!';
          errorMessageEmail.style.display = 'block';
          return;
      } else {
          email.classList.remove('error-border');
          errorMessageEmail.style.display = 'none';
      }
    } else {
        phone.classList.add('error-border');
        email.classList.add('error-border');
        errorMessagePhone.innerHTML = 'Por favor, insira um Telefone ou Email!';
        errorMessagePhone.style.display = 'block';
        errorMessageEmail.innerHTML = 'Por favor, insira um Telefone ou Email!';
        errorMessageEmail.style.display = 'block';
        return;
    }

    // Validation Message
    if (!message.value){
        message.classList.add('error-border');
        errorMessageText.innerHTML = 'O campo deve ser preenchido!';
        errorMessageText.style.display = 'block';
        return;
    } else if(message.value.length < 20){
        message.classList.add('error-border');
        errorMessageText.innerHTML = 'A mensagem deve conter um min. de 20 caracteres';
        errorMessageText.style.display = 'block';
        return;
    } else {
        message.classList.remove('error-border');
        errorMessageText.style.display = 'none';
    }

    // Call AJAX for send email
    const data = {
        method: 'POST',
        body: JSON.stringify({
            secretKey: secretKey,
            nome: name.value,
            email: email.value,
            phone: phone.value,
            mensagem: message.value
        })
    }

    const response = await fetch(url, data);

    if(!response.ok){
        emailMessage.classList.add('email-error-message');
        emailMessage.innerHTML = 'Opss! Houve um erro ao enviar o seu email. Por favor tente novamente';
        emailMessage.style.display = 'block';           
    } else {
        emailMessage.classList.add('email-success-message');
        emailMessage.innerHTML = 'Sua mensagem foi enviado com sucesso!';
        emailMessage.style.display = 'block';
        name.value = "";
        phone.value = "";
        email.value = "";
        message.value = "";
    }
}


function Form() {
  return (
    <form action="" className="main_contactus_form">
      <Input id={"name"} type={"text"} title={"Nome*"} placeholder={'Digite seu nome'} />
      <Input id={"phone"} type={"tel"} title={"Telefone*"} placeholder={'(XX) XXXX-XXXX'} handleValue={phoneMask}  maxLen={16}/>
      <Input id={"email"} type={"email"} title={"Email*"} placeholder={'seu@email.com'} />

      <div className="field-message">
        <label htmlFor="message">Mensagem*</label>
        <textarea name="message" id="message" placeholder="Digite aqui sua mensagem"></textarea>
        <div className="error-message"></div>
      </div>

      <div className="field-button">
        <div className="error email-message"></div>
        <button type="submit" onClick={validationForm}>
          Enviar <img src="./svg/icon-arrow-right.svg" alt="Seta" />
        </button>
      </div>
    </form>
  );
}

export default Form;
