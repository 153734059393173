import React from 'react'
import Link from '../Header/Link'
import SocialLink from './SocialLink'

function MainFooter() {
  return (
    <section className="main-footer container reveal">
        <ul className='main-footer_links'>
            <Link name={"O que fazemos"} dataID={"about-us"} />
            <Link name={"Quem faz"} dataID={"whodoes"} />
            <Link name={"Projetos"} dataID={"projects"} />
            <Link name={"Agenda"} dataID={"calendar-events"} />
            <Link name={"Parceiros"} dataID={"partners"} />
            <Link name={"Contato"} dataID={"contact-us"} />
        </ul>

        <div className="main-footer_social">
            <SocialLink href={'https://www.facebook.com/profile.php?id=100067757552559&mibextid=LQQJ4d'} title={'Facebook'} src={"./svg/icon-fb.svg"} alt={"facebook-icon"} />
            <SocialLink href={'https://instagram.com/associacao.arte.cultura_ofc?igshid=YmMyMTA2M2Y='} title={'Instagram'} src={"./svg/icon-ig.svg"} alt={"instagram-icon"} />
            <SocialLink href={' https://www.youtube.com/@aacbbassociacaoarteecultur7092'} title={'Youtube'} src={"./svg/icon-yt.svg"} alt={"youtube-icon"} />
        </div>
    </section>
  )
}

export default MainFooter