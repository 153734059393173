import React from "react";

function ProjectTagCard({ active, expiration, impact, partners }) {
  return (
    <>
      <div className="project_card_tag">
        <span className="project_card_tag_title">Ativo</span>
        <span className={`tag ${ active ? 'tag-active' : 'tag-inactive' }`}></span>
      </div>
      <div className="project_card_tag">
        <span className="project_card_tag_title">Período</span>
        <span>{expiration}</span>
      </div>
      <div className="project_card_tag">
        <span className="project_card_tag_title">Impacto</span>
        <span>{impact}</span>
      </div>
      <div className="project_card_tag">
        <span className="project_card_tag_title">Patrocínio</span>
        <span>{partners}</span>
      </div>
    </>
  );
}

export default ProjectTagCard;
