import React from "react";

function Article({ title, text, image, alt}) {
  return (
    <article>
      <img src={image} alt={alt} />
      <div className="about-us_articles_content">
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </article>
  );
}

export default Article;
