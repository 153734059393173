import React from "react";
import Link from "./Link";

function toggleMenu(e){
    const btnMobile = e.currentTarget;
    const menuMobile = document.querySelector('.main_header_content_nav_mobile');
    const body = document.querySelector('body');
    
    menuMobile.classList.toggle('active');

    const ariaExpanded = menuMobile.classList.contains('active');
    if(ariaExpanded){
        btnMobile.setAttribute('aria-expanded', ariaExpanded);
        btnMobile.setAttribute('aria-label', 'Fechar Menu');
        body.style.overflowY = 'hidden';
        
    } else{
        btnMobile.setAttribute('aria-expanded', ariaExpanded);
        btnMobile.setAttribute('aria-label', 'Abrir Menu');
        body.style.overflowY = 'auto';
    }
    
}

function MenuMobile() {
  return (
    <nav className="main_header_content_nav_mobile">
      <div>
      <button className="btn-menu-mobile" aria-label="Abrir Menu" aria-haspopup="true" aria-expanded="false"
            aria-controls="menu" onClick={toggleMenu}></button>
      </div>
      <ul className="menu menu-mobile" role="menu">
        <Link name="o que fazemos" href="./" dataID="about-us" />
        <Link name="quem faz" href="./" dataID="whodoes" />
        <Link name="projetos" href="./" dataID="projects" />
        <Link name="agenda" href="./" dataID="calendar-events" />
        <Link name="parceiros" href="./" dataID="partners" />
        <Link name="contato" href="./" dataID="contact-us" />
      </ul>
    </nav>
  );
}

export default MenuMobile;
