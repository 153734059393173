import React from "react";
import { scrollTo } from "../../assets/helpers/scrollHelper";

function NoEventsLayout() {
  return (
    <div className="calendar-events-noresults">
      ainda não temos eventos futuros programados. volte em breve ou{"  "}
      <a href="./" title="Contato" data-id="contact-us" onClick={scrollTo}>
        entre em contato
      </a>
      {"  "}conosco para mais informações.
    </div>
  );
}

export default NoEventsLayout;
