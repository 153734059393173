import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import data from "../../data-projects.json";

function getFiles(e){
  e.preventDefault();
  const dataHref = e.currentTarget.getAttribute('data-href');

  window.open(dataHref, "_blank");
}

function ProjectModal({sliderRef, closeModal}) {
  let expired;
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <div
      className="project_modal hide"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="projectModal"
      aria-hidden="true"
    >
      <div className="project_modal_container" role="document">
        <button
          className="project_modal_container_btn"
          aria-label="Fechar Modal"
          onClick={closeModal}
        ></button>
        <Slider {...settings} ref={sliderRef}>
          {data.map(({id, name, description, expiration, impact, partners, image, isFiles }) => {
            expired = expiration.includes("presente");
            return (
              <div key={id}>
                <div className="project_modal_slider">
                  <div className="project_modal_content">
                    <h3 className="project_modal_content_title">{name}</h3>
                    <p className="project_modal_content_description">{description}</p>
                  </div>

                  <img
                    className="project_modal_image"
                    src={image}
                    alt="Imagem Card"
                  />

                  <div className="project_modal_tags">
                    <div className="project_card_tag">
                      <span className="project_card_tag_title">Ativo</span>
                      <span
                        className={`tag ${
                          expired ? "tag-active" : "tag-inactive"
                        }`}
                      ></span>
                    </div>
                    <div className="project_card_tag">
                      <span className="project_card_tag_title">Período</span>
                      <span className="expiration_tag">{expiration}</span>
                    </div>
                    <div className="project_card_tag">
                      <span className="project_card_tag_title">Impacto</span>
                      <span className="impact_tag">{impact}</span>
                    </div>
                    <div className="project_card_tag">
                      <span className="project_card_tag_title">Patrocínio</span>
                      <span className="partners_tag">{partners}</span>
                    </div>
                  </div>

                  <div className="project_files">
                    <button className="project_files_btn" disabled={!isFiles ? true : false} data-href={isFiles} onClick={getFiles}>arquivo do projeto</button>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default ProjectModal;
