import React from "react";
import People from "./People";

const data = [
  {
    name: "Alan Santana",
    position: "Auxiliar Administrativo",
    image: "./images/image-alan.png",
  },
  {
    name: "Brenda Monteiro",
    position: "Professora de Ballet Clássico",
    image: "./images/image-brenda.png",
  },
  {
    name: "Isabela Fernandes",
    position: "Professora de Ballet Clássico",
    image: "./images/image-isabela.png",
  },
  {
    name: "Sabrina Santos",
    position: "Técnica Administrativa",
    image: "./images/image-sabrina.png",
  },
  {
    name: "Tauan Costa",
    position: "Professor de Taekwondo",
    image: "./images/image-tauan.png",
  },
  {
    name: "Tiago Maciel",
    position: "Coordenador Geral",
    image: "./images/image-tiago.png",
  },
];

function WhoDoes() {
  return (
    <section id="whodoes" className="whodoes container reveal">
      <h2 className="whodoes_title">QUEM FAZ</h2>

      <div className="whodoes_peoples">
        {data.map(({name, position, image}) => {
            return <People key={name} name={name} position={position} image={image} />
        })}
      </div>
    </section>
  );
}

export default WhoDoes;
