import React from "react";

function Input({id, type, title, placeholder, maxLen, handleValue}) {
  return (
    <div className={`main_contactus_form_control field-${id}`}>
      <label htmlFor={id}>{title}</label>
      <input id={id} name={id} type={type} placeholder={placeholder} maxLength={maxLen} onKeyDown={handleValue}></input>
      <div className="error-message"></div>
    </div>
  );
}

export default Input;
