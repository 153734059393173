import React from "react";

function Adress() {
  return (
    <div className="main_contact-us_address">
      <div className="main_contact-us_address_location">
        <h3>AACBB | SEDE</h3>
        <p>
          <span>BAIANÃO |</span> Rua 27 de maio 291 (Fundo da Comporto)
        </p>
      </div>
      <div className="main_contact-us_address_location">
        <h3>AACBB | NÚCLEOS CULTURAIS</h3>
        <p>
          <span>VERA CRUZ |</span> Creche cruz de malta, BR-101
        </p>
        <p>
          <span>SÃO FRANCISCO |</span> Rua das palmeiras 199, Arraial d'Ajuda,
          Associação Beneficente de Arraial d'Ajuda
        </p>
        <p>
          <span>CAMBOlO |</span> SENTOTESB (Entrada Principal)
        </p>
      </div>
    </div>
  );
}

export default Adress;
