import React from "react";
import Address from "./Address";
import Form from "./Form";

function ContactUs() {
  return (
    <section id="contact-us" className="main_contact-us container reveal">
      <h2 className="main_contact-us_title">CONTATO</h2>

      <Address />
      <Form />
    </section>
  );
}

export default ContactUs;
